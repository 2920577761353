import ToastManager from "./ToastManager";
import PusherHelper from "./PusherHelper";
import DatePickerHelper from "./DatePickerHelper";
import MultiSelectHelper from "./MultiSelectHelper";
import ThemeSwitcher from "./ThemeSwitcher";
import Navigation from "./Navigation";
import AjaxManager from "./AjaxManager";
import AjaxLoader from "./AjaxLoader";
import TimeoutRefresher from "./TimeoutRefresher";
import UserGroups from "./UserGroups";

export default class SetupGui {
  static run() {
    AjaxManager.initClass();
    ToastManager.initClass();

    $(function () {
      console.log("DOM loaded");

      AjaxLoader.hide();
      AjaxManager.getInstance().run();
      //
      // // bootstrap switch
      $(".default-switch").bootstrapSwitch("state");
      //
      // // bootstrap
      $("body").tooltip({
        selector: '[data-toggle="tooltip"]',
        container: "body",
      });
      //
      //
      $(".default-datepicker").datepicker({
        format: "dd.mm.yyyy",
        todayBtn: "linked",
        language: "de",
        autoclose: true,
        todayHighlight: true,
        calendarWeeks: false,
        orientation: "top left",
      });

      DatePickerHelper.initDatePickers();

      MultiSelectHelper.observeMultiSelects();
      MultiSelectHelper.initGroupDropdowns();
      MultiSelectHelper.initSimpleSelects();

      new PusherHelper();

      ToastManager.getInstance().run();

      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        onclick: null,
        showDuration: "1000",
        hideDuration: "1000",
        timeOut: "0",
        extendedTimeOut: "0",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
      };

      TimeoutRefresher.initClass();
      if (
        UserGroups.hasGroup("OPERATOR_BARCODE_LOGIN_ALLOWED") ||
        UserGroups.hasGroup("CUSTOMER_BARCODE_LOGIN_ALLOWED")
      ) {
        new TimeoutRefresher();
      }
    });

    $(window).on("load", () => {
      new ThemeSwitcher();
      new Navigation();
    });

    $(document).ready(() => {
      MultiSelectHelper.initTranslations();
    });
  }
}
