export default class Navigation {
  constructor() {
    $(".dropdown-submenu > a").on("click", function (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      $(e.currentTarget).toggleClass("open");

      if (window.innerWidth - rect.right < 260) {
        $(this).next("ul").addClass("left");
      } else {
        $(this).next("ul").removeClass("left");
      }

      $(this).next("ul").toggle();
      $(this).next("ul").find("ul").hide();
      $(this).next("ul").find("a").removeClass("open");

      $(e.currentTarget.parentElement)
        .siblings(".dropdown-submenu")
        .each((i, el) => {
          $(el).find("ul").hide();
          $(el).find("a").removeClass("open");
        });

      e.stopPropagation();
      e.preventDefault();
    });

    $(window).on("click focus", (e) => {
      $(".dropdown-submenu .dropdown-menu").hide();
      $(".dropdown-submenu > a").removeClass("open");
    });

    $(".dropdown-toggle").on("click focus", (e) => {
      $(".dropdown-submenu .dropdown-menu").hide();
      $(".dropdown-submenu > a").removeClass("open");
    });
  }
}
