import AjaxLoader from "./AjaxLoader";
import StateHandler from "./StateHandler";
import AjaxHandler from "./AjaxHandler";

export default class ThemeSwitcher {
  constructor() {
    $("#theme-switcher").on("change", (e) => {
      const url = `/resources/users/toggle-theme`;
      const type = "POST";

      $.ajax({
        url,
        type,
        success: (result) => {
          AjaxLoader.hide();
          if (result.state === 1) {
            StateHandler.show(result.title, result.content, "success");
            $(".navbar-fixed-top")
              .removeClass(result.class.oldValue)
              .addClass(result.class.newValue);
          } else {
            StateHandler.show(result.title, result.content, "danger");
          }
        },
        error: (error) => {
          return AjaxHandler.handleError(error);
        },
      });
    });

    $(".theme-mode-box").on("click focus", (e) => {
      e.stopPropagation();
    });
  }
}
