import AjaxHandler from "./AjaxHandler";
import Pusher from "pusher-js";

export default class PusherHelper {
  constructor() {
    const notificationsWrapper = $(".dropdown-notifications");
    const notificationsToggle = notificationsWrapper.find("a[data-toggle]");
    const notificationsCountElem = notificationsToggle.find("i[data-count]");
    let notificationsCount = parseInt(notificationsCountElem.data("count"));
    const notifications = notificationsWrapper.find("ul.dropdown-menu");

    const pusher = new Pusher(process.env.MIX_PUSHER_APP_KEY, {
      cluster: process.env.MIX_PUSHER_APP_CLUSTER,
      encrypted: true,
    });

    //    Pusher.logToConsole = true;

    const channel = pusher.subscribe("notification-update");

    channel.bind("App\\Events\\NotificationUpdate", (data) => {
      const userId = $("body").data("user");
      const { notification } = data;

      if (userId === notification.receiver_user_id) {
        const existingNotifications = notifications.html();

        const newNotificationHtml =
          `\<li class="notification active" data-id="` +
          notification.id +
          '" data-link="' +
          notification.link +
          `"> \
<div class="media"> \
<div class="media-body"> \
<strong class="notification-title">` +
          notification.title +
          `</strong> \
<p class="notification-desc">` +
          notification.message +
          `</p> \
<div class="notification-meta"> \
<small class="timestamp">about a minute ago</small> \
</div> \
</div> \
</div> \
</li>\
`;

        notifications.html(newNotificationHtml + existingNotifications);
        notificationsCount += 1;
        notificationsCountElem.attr("data-count", notificationsCount);
        notificationsWrapper.find(".notify-count").text(notificationsCount);
        notificationsWrapper
          .find(".notifications-counter")
          .addClass("notification-icon");
        return notificationsWrapper.show();
      }
    });

    $(".notification-link").click((event) => {
      const $this = $(event.delegateTarget);
      const element = $this.closest(".notification");

      const id = element.data("id");
      const link = element.data("link");
      const url = `/notifications/${id}/update`;
      const type = "POST";

      $.ajax({
        url,
        type,
        success: (result) => {
          return console.log("done");
        },
        error: (error) => {
          return AjaxHandler.handleError(error);
        },
      });

      return (window.location = link);
    });
  }
}
