export default class AjaxLoader {
  static show(status) {
    const loader = $(".loading");
    loader.find(".status").text(status);
    return loader.css("display", "inline-block");
  }

  static showAndDisable(status, elementSelector) {
    $(elementSelector).bind("click", false);
    return AjaxLoader.show(status);
  }

  static hide() {
    const loader = $(".loading");
    return loader.hide();
  }

  static hideAndEnable(elementSelector) {
    $(elementSelector).unbind("click", false);
    return AjaxLoader.hide();
  }
}
