require("./libs");
import SetupGui from "./classes/SetupGui";

/**
 * Setup CSRF Token for ajax requests
 * https://laravel.com/docs/9.x/csrf#csrf-x-csrf-token
 */
$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": $('meta[name="_token"]').attr("content"),
  },
});

SetupGui.run();
