export default class StateHandler {
  static show(title, content, status, containerSelector = "#state-container") {
    const container = $(containerSelector);
    container
      .find(".close")
      .bind("click", () => StateHandler.hide(containerSelector));
    const alert = container.find(".alert");
    alert.removeClass("alert-danger");
    alert.removeClass("alert-warning");
    alert.removeClass("alert-success");

    if (status === "warning") {
      alert.addClass("alert-warning");
    } else if (status === "info") {
      alert.addClass("alert-info");
    } else if (status === "danger") {
      alert.addClass("alert-danger");
    } else {
      alert.addClass("alert-success");
    }

    if (title.length > 0) {
      const titleContainer = container.find(".title");
      titleContainer.text(title);
      titleContainer.show();
    } else {
      container.find(".title").hide();
    }

    container.find(".content").html(content);
    return container.hide().fadeIn();
  }

  static hide(containerSelector = "#state-container") {
    const container = $(containerSelector);
    container.find(".close").unbind("click");
    return container.fadeOut();
  }
}
