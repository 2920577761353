import AjaxLoader from "./AjaxLoader";

export default class AjaxManager {
  static instance;

  static initClass() {
    AjaxManager.instance = null;
  }

  static getInstance() {
    return AjaxManager.instance != null
      ? AjaxManager.instance
      : (AjaxManager.instance = new AjaxManager());
  }

  constructor() {
    this.requests = [];
    this.timeoutId = -1;
  }

  addRequest(options) {
    return this.requests.push(options);
  }

  run() {
    if (this.requests.length > 0) {
      AjaxLoader.show();
      const originalComplete = this.requests[0].complete;

      this.requests[0].complete = () => {
        if (typeof originalComplete === "function") {
          originalComplete();
        }

        this.requests.shift();

        return this.run();
      };

      return $.ajax(this.requests[0]);
    } else {
      AjaxLoader.hide();
      return (this.timeoutId = setTimeout(() => {
        return this.run();
      }, 250));
    }
  }

  stop() {
    this.requests = [];
    return clearTimeout(this.timeoutId);
  }
}
