export default class MultiSelectHelper {
  static observeMultiSelects() {
    this._observeValueChanges();
    this._initSelectItemTitle();

    setTimeout(() => {
      document
        .querySelectorAll(".bootstrap-select.bs3:not(.btn-group)")
        .forEach((el) => {
          el.classList.add("btn-group");
        });
    }, 100);


    const observer = new MutationObserver((mutationList) => {
      document
        .querySelectorAll(".bootstrap-select.bs3:not(.btn-group)")
        .forEach((el) => {
          el.classList.add("btn-group");
        });

      for (const listItem of mutationList) {
        if (
          !(listItem.target instanceof HTMLElement) ||
          !listItem.target.closest(".custom-select-box")
        ) {
          continue;
        }

        if (listItem.target instanceof HTMLLIElement) {
          const dropdown = listItem.target.closest(
            ".dropdown-menu:not(.inner)"
          );

          if (dropdown && !this.dropdownQueue.has(dropdown)) {
            this.dropdownQueue.add(dropdown);

            setTimeout(() => {
              this._updateSelectAllButton(dropdown);
              this.dropdownQueue.delete(dropdown);
            }, 50);
          }
        }

        if (listItem.target instanceof HTMLDivElement) {
          const dropdownContainer = listItem.target;

          if (dropdownContainer) {
            this._initMultiSelectNavigation(dropdownContainer);

            if (dropdownContainer.matches(".bootstrap-select")) {
              const groupDropdown =
                dropdownContainer.querySelector(".has_group");

              if (groupDropdown) {
                this._updateDropdownPosition(dropdownContainer);

                if (dropdownContainer.classList.contains("open")) {
                  //this.groupChildrenToInsert.set(groupDropdown, [...this.groupChildren.get(groupDropdown)]);
                  this._showChildrenMenuGradually(groupDropdown);
                } else {
                  //this._hideChildrenMenu(groupDropdown);
                }
              }
            }
          }
        }
      }
    });

    setTimeout(() => {
      observer.observe(document.body, {
        attributeFilter: ["class"],
        subtree: true,
      });

      document
        .querySelectorAll(".dropdown-menu:not(.inner)")
        .forEach((dropdown) => {
          this._updateSelectAllButton(dropdown);
        });
    }, 100);
  }

  static initTranslations() {
    document
      .querySelectorAll("select.form-control.selectpicker")
      .forEach((select) => {
        $(select).data("none-selected-text", translate("none"));
        $(select).data("none-results-text", translate("no-results"));
        $(select).data("select-all-text", translate("select-all"));
        $(select).data("count-selected-text", (numSelected, numTotal) => {
          if (numSelected === numTotal) {
            return translate("all") + ` (${numTotal})`;
          }

          if (numSelected > 1) {
            return translate("count-selected-plural");
          }

          return translate("count-selected");
        });

        if (select.closest(".bootstrap-select")) {
          $(select).selectpicker("refresh");
        }
      });
  }

  static _getRealInputOrSelect(inputOrSelect) {
    if (!(inputOrSelect instanceof HTMLElement)) {
      inputOrSelect = $(inputOrSelect)[0];
    }

    if (
      inputOrSelect instanceof HTMLSelectElement &&
      inputOrSelect.dataset.options
    ) {
      if (!this.shadowSelects.has(inputOrSelect)) {
        const shadowSelect = document.createElement("select");
        const optionData = JSON.parse(inputOrSelect.dataset.options);
        const options = optionData.map((item) => {
          const option = document.createElement("option");
          option.innerText = item.label;
          option.selected = item.selected;
          option.value = item.value;

          if (item.alwaysShow) {
            option.dataset.alwaysShow = "1";
          }

          if (item.subtext) {
            option.dataset.subtext = item.subtext;
          }

          return option;
        });

        shadowSelect.multiple = true;
        shadowSelect.replaceChildren(...options);
        this.shadowSelects.set(inputOrSelect, shadowSelect);
      }

      return this.shadowSelects.get(inputOrSelect);
    }

    return inputOrSelect;
  }

  static getChildrenSelect(select) {
    return $(this._getRealInputOrSelect(select));
  }

  static _observeValueChanges() {
    document
      .querySelectorAll(".custom-select-box input, .custom-select-box select")
      .forEach((el) => {
        el.addEventListener("change", (e) => {
          this._updateModifiedStatus(e.target);
        });

        this._updateModifiedStatus(el);
      });
  }

  static _updateModifiedStatus(inputOrSelect) {
    if (!(inputOrSelect instanceof HTMLElement)) {
      return;
    }

    const container = inputOrSelect.closest(".custom-select-box");

    if (!container) {
      return;
    }

    if (
      inputOrSelect instanceof HTMLSelectElement &&
      inputOrSelect.dataset.defaultOptionValues
    ) {
      const defaultValues = JSON.parse(
        inputOrSelect.dataset.defaultOptionValues
      );
      let groupsJoined = "",
        defaultGroupsJoined = "",
        defaultOptionsJoined = "";
      const realSelect = this._getRealInputOrSelect(inputOrSelect);
      const optionsJoined = Object.values(
        realSelect.querySelectorAll("option:checked")
      )
        .map((option) => option.value)
        .sort()
        .join();

      if (defaultValues === "ALL") {
        defaultOptionsJoined = Object.values(
          realSelect.querySelectorAll("option")
        )
          .map((option) => option.value)
          .sort()
          .join();
      } else {
        defaultOptionsJoined = defaultValues.sort().join();
      }

      if (inputOrSelect.dataset.defaultGroupValues) {
        groupsJoined = this.getSelectedGroupIds(inputOrSelect).sort().join();
        const defaultGroups = JSON.parse(
          inputOrSelect.dataset.defaultOptionValues
        );

        if (defaultGroups === "ALL") {
          defaultGroupsJoined = this.getAllGroups(inputOrSelect)
            .map((group) => group.id)
            .sort()
            .join();
        } else {
          defaultGroupsJoined = defaultGroups.sort().join();
        }
      }

      if (
        defaultGroupsJoined === groupsJoined &&
        defaultOptionsJoined === optionsJoined
      ) {
        container.classList.remove("values-changed");
      } else {
        container.classList.add("values-changed");
      }
    } else if (
      inputOrSelect instanceof HTMLInputElement &&
      inputOrSelect.classList.contains("f_input")
    ) {
      if (inputOrSelect.value == inputOrSelect.dataset.defaultValue) {
        container.classList.remove("value-changed");
      } else {
        container.classList.add("value-changed");
      }
    }
  }

  static _initMultiSelectNavigation(dropdown) {
    dropdown = dropdown.closest(".bootstrap-select");

    if (dropdown && !dropdown.dataset.hasListener) {
      dropdown.dataset.hasListener = "true";

      dropdown.addEventListener(
        "keydown",
        (e) => {
          if (
            e.target instanceof HTMLElement &&
            e.target.matches(".bs-searchbox input, .bs-actionsbox button")
          ) {
            const selectElement = e.target.closest(
              ".dropdown-menu:not(.inner)"
            );

            if (
              e.target instanceof HTMLInputElement &&
              e.target.closest(".not-visible")
            ) {
              if (
                e.code === "Space" ||
                (e.key === "Enter" && !selectElement.querySelector("li.active"))
              ) {
                const selectAllToggles = selectElement.querySelectorAll(
                  ".bs-actionsbox button"
                );

                if (selectAllToggles.length === 2) {
                  if (selectAllToggles[0].classList.contains("selected")) {
                    selectAllToggles[1].click();
                  } else {
                    selectAllToggles[0].click();
                  }
                }

                e.stopPropagation();
                e.preventDefault();
              } else if (
                e.key !== "ArrowUp" &&
                e.key !== "ArrowDown" &&
                e.key !== "Enter" &&
                e.code !== "Tab"
              ) {
                e.stopPropagation();
                e.preventDefault();
              }
            } else if (
              e.target instanceof HTMLInputElement &&
              e.code === "Tab" &&
              !e.shiftKey
            ) {
              const selectAllToggles = selectElement.querySelectorAll(
                ".bs-actionsbox button"
              );

              if (selectAllToggles.length === 2) {
                e.stopPropagation();
              }
            } else if (
              e.target instanceof HTMLButtonElement &&
              (e.key === "Enter" || e.code === "Space")
            ) {
              setTimeout(() => {
                if (e.target.classList.contains("bs-select-all")) {
                  e.target.nextElementSibling.focus();
                } else {
                  e.target.previousElementSibling.focus();
                }
              }, 10);

              e.target.click();
              e.stopPropagation();
            }
          }
        },
        true
      );
    }
  }

  static _initSelectItemTitle() {
    function getTextRecursively(element) {
      let text = "";

      for (const node of element.childNodes) {
        if (node instanceof HTMLElement) {
          text += " " + getTextRecursively(node);
        } else {
          text += node.textContent;
        }
      }

      return text;
    }

    window.addEventListener("mouseover", (e) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.matches(
          ".custom-select-box .dropdown-menu > li > a, .groups-container > button"
        )
      ) {
        e.target.title = getTextRecursively(e.target);
      }
    });
  }

  static _updateSelectAllButton(dropdown) {
    const selectAllButton = dropdown.querySelector(".bs-select-all");
    const deselectAllButton = dropdown.querySelector(".bs-deselect-all");

    if (selectAllButton) {
      const items = this.groupChildren.get(dropdown);

      const allItems = items
        ? items.reduce(
            (acc, cur) => (!cur.classList.contains("hidden") ? 1 : 0) + acc,
            0
          )
        : dropdown.querySelectorAll(".dropdown-menu li:not(.hidden)").length;
      const selectedItems = items
        ? items.reduce(
            (acc, cur) =>
              (cur.classList.contains("selected") &&
              !cur.classList.contains("hidden")
                ? 1
                : 0) + acc,
            0
          )
        : dropdown.querySelectorAll(".dropdown-menu li:not(.hidden).selected")
            .length;

      const isSearchHidden =
        !!dropdown.querySelector(".bs-searchbox") &&
        dropdown
          .querySelector(".bs-searchbox")
          .classList.contains("not-visible");

      if (allItems === selectedItems) {
        selectAllButton.classList.add("selected");
        selectAllButton.tabIndex = -1;
        deselectAllButton.tabIndex = isSearchHidden ? -1 : 0;
      } else {
        selectAllButton.classList.remove("selected");
        selectAllButton.tabIndex = isSearchHidden ? -1 : 0;
        deselectAllButton.tabIndex = -1;
      }
    }
  }

  static _updateDropdownPosition(dropdownContainer) {
    const button = dropdownContainer.querySelector("button");
    const rect = button.getBoundingClientRect();

    const dropdown = dropdownContainer.querySelector(".dropdown-menu");

    if (dropdownContainer.classList.contains("open")) {
      setTimeout(() => {
        const input = dropdown.querySelector(".search-groups");

        if (input && input.parentElement.style.display !== "none") {
          input.focus();
        } else {
          const allGroupsButton = dropdown.querySelector(".all-groups-btn");

          if (allGroupsButton) {
            allGroupsButton.focus();
          }
        }
      }, 20);
    }

    if (window.innerWidth - rect.right < 600) {
      dropdown.style.left = "auto";
      dropdown.style.right = "0";
    } else {
      dropdown.style.left = "0";
      dropdown.style.right = "";
    }
  }

  static initGroupDropdowns() {
    document
      .querySelectorAll("select[data-groups]")
      .forEach((selectElement) => {
        this.initGroupDropdown(selectElement);
      });
  }

  static _constructDropdown(selectElement) {
    const selectBox = selectElement.closest(".custom-select-box");
    const dropdownContainer = document.createElement("div");
    dropdownContainer.className = `custom-select btn-group bootstrap-select show-tick form-control ${selectElement.id}`;
    const dropdown = selectElement.previousElementSibling;
    let filteroption2 = "";
    if (selectElement.classList.contains("option2enabled")) {
      filteroption2 = '<span class="filter-option2 pull-left">-</span>';
    }

    dropdownContainer.innerHTML =
      `<button type="button" class="btn dropdown-toggle btn-default" role="button" data-id="${selectElement.id}" title="" data-group-status-text="">
          <span class="filter-option pull-left"></span>${filteroption2}&nbsp;<span class="bs-caret"><span class="caret"></span></span></button>` +
      `<div class="dropdown-menu" role="combobox" style="max-height: 744px; overflow: hidden; min-height: 168px;">
               <div class="bs-searchbox"><input type="text" class="form-control" autocomplete="off" role="textbox"></div>
               <div class="bs-actionsbox" style=""><div class="btn-group btn-group-sm btn-block">
               <button type="button" class="actions-btn bs-select-all btn btn-default" tabindex="0">${translate(
                 "all"
               )}</button>
               <button type="button" class="actions-btn bs-deselect-all btn btn-default" tabindex="-1">${translate(
                 "none"
               )}</button>
               </div>
               </div>
               <ul class="dropdown-menu inner" role="listbox" style="max-height: 656px; overflow-y: auto; min-height: 80px; display: block;"></ul>
           </div>`;

    dropdownContainer.appendChild(selectElement);
    selectBox.appendChild(dropdownContainer);

    if (selectElement.classList.contains("option2enabled")) {
      this.initOption2DropDown(selectElement);
    }

    const toggle = dropdownContainer.querySelector(".dropdown-toggle");

    toggle.onclick = (e) => {
      document
        .querySelectorAll('.dropdown-toggle[aria-expanded="true"]')
        .forEach((t) => {
          t.click();
        });

      setTimeout(() => {
        dropdownContainer.classList.toggle("open");
        toggle.classList.toggle("active");
      }, 10);

      e.stopPropagation();
    };

    const label = selectBox.querySelector("label");

    label.onmouseup = (e) => {
      setTimeout(() => {
        toggle.focus();
      });
    };

    function onBlur(e) {
      if (
        !(e.target instanceof HTMLElement) ||
        !dropdownContainer.contains(e.target)
      ) {
        dropdownContainer.classList.remove("open");
        toggle.classList.remove("active");
      }
    }

    window.addEventListener("click", onBlur, true);
    window.addEventListener("focus", onBlur, true);
    window.addEventListener("mouseup", onBlur, true);
    window.addEventListener("mousedown", onBlur, true);

    const searchInput = dropdownContainer.querySelector(".bs-searchbox input");
    const dropdownElement = dropdownContainer.querySelector(".dropdown-menu");

    dropdownElement.querySelector("ul").onscroll = (e) => {
      this._showChildrenMenuGradually(dropdownElement);
    };

    searchInput.oninput = () => {
      const children = this.groupChildren.get(dropdownElement);

      if (children && !dropdownElement.dataset.searchingChildren) {
        dropdownElement.dataset.searchingChildren = "true";

        setTimeout(() => {
          const value = searchInput.value.toLowerCase().trim();
          const filteredChildren = [];

          children.forEach((child) => {
            if (child.innerHTML.toLowerCase().includes(value)) {
              child.classList.remove("hidden");
              filteredChildren.push(child);
            } else {
              child.classList.add("hidden");
            }

            child.classList.remove("active");
          });

          this.groupChildrenToInsert.set(dropdownElement, filteredChildren);
          dropdownElement.querySelector("ul").innerHTML = "";
          dropdownElement.dataset.searchingChildren = "";
          this._showChildrenMenuGradually(dropdownElement);
        }, 100);
      }
    };

    const realSelect = this._getRealInputOrSelect(selectElement);

    dropdownContainer.querySelector(".bs-select-all").onclick = () => {
      const children = this.groupChildren.get(dropdownElement);

      children.forEach((child) => {
        if (!child.classList.contains("hidden")) {
          child.classList.add("selected");
          realSelect.children[child.dataset.index].selected = true;
        }
      });

      $(selectElement).trigger("change");
      $(realSelect).trigger("change");
    };

    dropdownContainer.querySelector(".bs-deselect-all").onclick = () => {
      const children = this.groupChildren.get(dropdownElement);

      children.forEach((child) => {
        if (!child.classList.contains("hidden")) {
          child.classList.remove("selected");
          realSelect.children[child.dataset.index].selected = false;
        }
      });

      $(selectElement).trigger("change");
      $(realSelect).trigger("change");
    };

    searchInput.onkeydown = (e) => {
      let children = this.groupChildrenToInsert.get(dropdownElement);

      if (children && children.length) {
        if (e.code === "ArrowUp" || e.code === "ArrowDown") {
          e.preventDefault();

          let childIndex = children.findIndex((item) =>
            item.classList.contains("active")
          );

          if (childIndex < 0) {
            childIndex = 0;

            if (children[childIndex]) {
              children[childIndex].classList.add("active");
            }
          } else {
            let newChildIndex = childIndex;

            if (e.code === "ArrowUp") {
              --newChildIndex;
            } else {
              ++newChildIndex;
            }

            if (children[newChildIndex]) {
              children[childIndex].classList.remove("active");
              children[newChildIndex].classList.add("active");

              if (children[newChildIndex].parentElement) {
                const offsetTop =
                  children[newChildIndex].getBoundingClientRect().y -
                  children[newChildIndex].parentElement.getBoundingClientRect()
                    .y;

                if (offsetTop <= 0 || offsetTop > 135) {
                  children[newChildIndex].parentElement.scrollTop +=
                    offsetTop - 50;
                }
              }
            }
          }

          this._showChildrenMenuGradually(dropdownElement);
        } else if (e.key === "Enter") {
          e.preventDefault();
          const activeChild = children.find((item) =>
            item.classList.contains("active")
          );

          if (activeChild) {
            activeChild.firstElementChild.click();
          }
        }
      }
    };
  }

  static _reloadGroupChildren(selectElement, dropdownElement) {
    let selectedChildrenCount = 0;
    const elements = [];
    let i = 0;
    const realSelect = this._getRealInputOrSelect(selectElement);

    for (const option of realSelect.children) {
      const element = document.createElement("li");
      element.dataset.index = i++;

      if (option.selected) {
        ++selectedChildrenCount;
        element.classList.add("selected");
      }

      element.innerHTML = `<a><span class="text">${option.innerHTML} <span class="text-muted"></span></span><span class="glyphicon glyphicon-ok check-mark"></span></a>`;

      if (option.dataset.subtext) {
        element.firstElementChild.firstElementChild.firstElementChild.innerText =
          option.dataset.subtext;
      }

      element.onclick = (e) => {
        const listItem = e.target.closest("li");
        const optionToChange = realSelect.children[listItem.dataset.index];
        optionToChange.selected = !optionToChange.selected;
        listItem.classList.toggle("selected");
        e.stopPropagation();
        $(selectElement).trigger("change");
        $(realSelect).trigger("change");
      };

      elements.push(element);
    }

    dropdownElement.querySelector(".bs-searchbox input").value = "";

    this.groupChildren.set(dropdownElement, elements);
    this.groupChildrenToInsert.set(dropdownElement, elements);
    dropdownElement.querySelector("ul").innerHTML = "";
    this._showChildrenMenuGradually(dropdownElement);

    if (selectedChildrenCount === elements.length) {
      dropdownElement.querySelector(".bs-select-all").classList.add("selected");
    } else {
      dropdownElement
        .querySelector(".bs-select-all")
        .classList.remove("selected");
    }
  }

  static initGroupDropdown(selectElement) {
    if (!(selectElement instanceof HTMLElement)) {
      selectElement = $(selectElement)[0];
    }

    let dropdown = selectElement.previousElementSibling;

    if (!dropdown || !dropdown.classList.contains("dropdown-menu")) {
      this._constructDropdown(selectElement);
      dropdown = selectElement.previousElementSibling;
    }

    if (dropdown.classList.contains("has_group")) {
      return;
    }

    dropdown.closest(".bootstrap-select").style.pointerEvents = "none";

    this._addGroups(
      selectElement,
      dropdown,
      JSON.parse(selectElement.dataset.groups),
      selectElement.dataset.groupChildrenTitle,
      selectElement.dataset.groupParentsTitle,
      selectElement.dataset.groupAllTitle
    );
    dropdown.classList.add("has_group");
  }

  static initOption2DropDown(selectElement) {
    if (!(selectElement instanceof HTMLElement)) {
      selectElement = $(selectElement)[0];
    }
    const dropdown = selectElement.previousElementSibling;
    if (dropdown.classList.contains("has_option2")) {
      return;
    }
    let groupChildrenTitle2 = selectElement.dataset.groupChildrenTitle2;
    let buttons = [];
    let options = JSON.parse(selectElement.dataset.options2);

    let html =
      "<h4>" +
      groupChildrenTitle2 +
      "</h4>" +
      '<div class="options2-input-container"><input type="text" class="form-control search-option2"  autocomplete="off" role="textbox" aria-label="Search" />' +
      "</div>" +
      '<button class="all-options-btn">Alle</button>' +
      '<div class="options-container"></div>';
    const dropdownContainer = document.createElement("div");
    dropdownContainer.classList.add("option2-container");
    dropdownContainer.insertAdjacentHTML("afterbegin", html);
    dropdown.appendChild(dropdownContainer);

    let oc = dropdown.querySelector(".options-container");

    for (let index in options) {
      let item = options[index];

      const button = document.createElement("button");
      button.className = `option-btn ${item.selected ? "selected" : ""}`;
      button.dataset.id = item.value;
      button.innerHTML =
        item.label +
        (item.subtext
          ? ' <span class="text-muted">' + item.subtext + "</span>"
          : "");

      //if (item.selected) {
      button.classList.add("active");
      //}
      buttons.push(button);
      oc.appendChild(button);
      // Element
      button.addEventListener(
        "click",
        (e) => {
          e.target.classList.toggle("selected");
          this._updateSelectAllOptions2Button(selectElement, dropdownContainer);
          $(selectElement).trigger("change");
        },
        true
      );
    }
    this.allOption2Buttons.set(selectElement, buttons);

    this.filteredOptions2Buttons.set(
      selectElement,
      this.allOption2Buttons.get(selectElement)
    );

    // Select all
    dropdownContainer.querySelector(".all-options-btn").onclick = (element) => {
      const children = this.filteredOptions2Buttons.get(selectElement);
      const toselect = !element.target.classList.contains("selected");
      children.forEach((child) => {
        if (!child.classList.contains("hidden")) {
          if (toselect) {
            child.classList.add("selected");
          } else {
            child.classList.remove("selected");
          }

          //realSelect.children[child.dataset.index].selected = true;
        }
      });
      if (toselect) {
        element.target.classList.add("selected");
      } else {
        element.target.classList.remove("selected");
      }

      $(selectElement).trigger("change");
    };

    // Search input

    dropdownContainer.querySelector(".search-option2").onchange =
      dropdownContainer.querySelector(".search-option2").onkeydown =
      dropdownContainer.querySelector(".search-option2").oninput =
        (e) => {
          const input = dropdownContainer.querySelector(".search-option2");

          if (
            !dropdownContainer.dataset.searchingOptions2 &&
            input.style.display !== "none"
          ) {
            dropdownContainer.dataset.searchingOptions2 = "true";

            setTimeout(() => {
              const value = input.value.toLowerCase().trim();
              const optionsContainer =
                dropdownContainer.querySelector(".options-container");
              optionsContainer.innerHTML = "";

              this.filteredOptions2Buttons.set(
                selectElement,
                this.activeOptions2Buttons
                  .get(selectElement)
                  .filter(function (button) {
                    return (
                      button.innerHTML.toLowerCase().includes(value) &&
                      button.classList.contains("active")
                    );
                  })
              );
              this._refreshOption2Container(selectElement, optionsContainer);
              this._updateSelectAllOptions2Button(
                selectElement,
                dropdownContainer
              );
              dropdownContainer.dataset.searchingOptions2 = "";
            }, 50);
          }
        };

    this._updateSelectAllOptions2Button(selectElement, dropdownContainer);

    dropdown.classList.add("has_option2");
  }

  static _updateSelectAllOptions2Button(selectElement, container) {
    const filteredOptions2Buttons =
      this.filteredOptions2Buttons.get(selectElement);
    const visibleSelectedGroupsCount = filteredOptions2Buttons.filter(
      (button) => button.classList.contains("selected")
    ).length;

    const visibleGroupsCount = filteredOptions2Buttons.length;
    if (visibleSelectedGroupsCount === visibleGroupsCount) {
      container.querySelector(".all-options-btn").classList.add("selected");
    } else {
      container.querySelector(".all-options-btn").classList.remove("selected");
    }
  }

  static _refreshOption2Container(selectElement, container) {
    if (
      container.children.length < 20 ||
      container.scrollTop >= container.scrollHeight - 400
    ) {
      const optionsToInsert = this.filteredOptions2Buttons
        .get(selectElement)
        .filter((button) => !button.parentElement);
      container.append(...optionsToInsert);
    }
  }

  static _refreshGroupView(groupContainer) {
    if (
      groupContainer.children.length < 20 ||
      groupContainer.scrollTop >= groupContainer.scrollHeight - 400
    ) {
      const groupsToInsert = this.filteredGroupButtons
        .get(groupContainer.closest(".dropdown-menu"))
        .filter((button) => !button.parentElement)
        .slice(0, 20);
      groupContainer.append(...groupsToInsert);
    }
  }

  static _addGroups(
    selectElement,
    dropdownElement,
    groups,
    groupChildrenTitle,
    groupParentsTitle,
    groupAllTitle
  ) {
    let html = '<div class="group-container">';
    html += `<h4 class="group-container-title">${groupParentsTitle}</h4>`;
    html += `<div class="group-input-container" ${
      selectElement.dataset.liveSearch && groups.length > 5
        ? ""
        : 'style="display: none"'
    }>
          <input type="text" class="form-control search-groups" autocomplete="off" role="textbox" aria-label="Search"></div>`;
    html += `<button class="all-groups-btn">${groupAllTitle}</button>`;
    html += '<div class="groups-container"></div></div>';

    const groupButtons = [];

    for (const group of groups) {
      const button = document.createElement("button");
      button.tabIndex = -1;
      button.className = `group-btn ${group.selected ? "selected" : ""}`;
      button.dataset.id = group.id;
      button.innerText = group.label;
      groupButtons.push(button);
    }

    this.allGroupsButtons.set(dropdownElement, groupButtons);
    this.filteredGroupButtons.set(dropdownElement, groupButtons);

    if (groupChildrenTitle) {
      html += `<h4>${groupChildrenTitle}</h4>`;
    }

    dropdownElement.insertAdjacentHTML("afterbegin", html);

    const groupContainer = dropdownElement.querySelector(".groups-container");
    this._refreshGroupView(groupContainer);

    groupContainer.onscroll = (e) => {
      this._refreshGroupView(groupContainer);
    };

    $(selectElement).on("change", () => {
      this._updateGroupsStatusText(
        selectElement,
        dropdownElement,
        groups.length
      );
      this._updateModifiedStatus(selectElement);
    });

    this._initGroupButtonClick(selectElement, dropdownElement, groups);
    this._initGroupSearchAndNavigation(dropdownElement);
  }

  static _updateGroupsStatusText(
    selectElement,
    dropdownElement,
    totalGroupsCount
  ) {
    const button = dropdownElement
      .closest(".bootstrap-select")
      .querySelector("button");
    const groupChildren = this.groupChildren.get(dropdownElement);

    if (
      !groupChildren ||
      !groupChildren.find((item) => item.classList.contains("selected"))
    ) {
      delete button.dataset.groupStatusText;
      button.classList.add("bs-placeholder");
      button.querySelector(".filter-option").innerText =
        translate("please-choose");
    } else {
      const selectedGroupTexts = this.allGroupsButtons
        .get(dropdownElement)
        .filter((button) => button.classList.contains("selected"))
        .map((btn) => btn.textContent);
      button.classList.remove("bs-placeholder");

      if (selectedGroupTexts.length < totalGroupsCount) {
        if (selectedGroupTexts.length === 0) {
          delete button.dataset.groupStatusText;
        } else if (selectedGroupTexts.length < 2) {
          button.dataset.groupStatusText = selectedGroupTexts.join(", ");
        } else {
          button.dataset.groupStatusText = translate("selected").replace(
            "{0}",
            selectedGroupTexts.length.toString()
          );
        }
      } else {
        button.dataset.groupStatusText =
          translate("all") + ` (${totalGroupsCount})`;
      }

      const selectedChildren = groupChildren.filter((item) =>
        item.classList.contains("selected")
      );

      if (selectedChildren.length === groupChildren.length) {
        button.querySelector(".filter-option").innerText =
          translate("all") + " (" + selectedChildren.length + ")";
      } else if (selectedChildren.length < 3) {
        button.querySelector(".filter-option").innerText = selectedChildren
          .map((item) => item.textContent)
          .join(", ");
      } else {
        button.querySelector(".filter-option").innerText = translate(
          "selected"
        ).replace("{0}", selectedChildren.length);
      }
    }

    if (dropdownElement.classList.contains("has_option2")) {
      if (this.activeOptions2Buttons.get(selectElement)) {
        if (
          !groupChildren ||
          !groupChildren.find((item) => item.classList.contains("selected")) ||
          !this.activeOptions2Buttons
            .get(selectElement)
            .find((item) => item.classList.contains("selected"))
        ) {
          delete button.dataset.groupStatusText;
          button.classList.add("bs-placeholder");
          button.querySelector(".filter-option").innerText =
            translate("please-choose");
          button.querySelector(".filter-option2").classList.add("hide");
        } else {
          let selectedOptions = this.activeOptions2Buttons
            .get(selectElement)
            .filter((item) => item.classList.contains("selected"));
          if (
            selectedOptions.length ==
            this.activeOptions2Buttons.get(selectElement).length
          ) {
            button.querySelector(".filter-option2").innerText =
              translate("all") + " (" + selectedOptions.length + ")";
          } else {
            button.querySelector(".filter-option2").innerText = translate(
              "selected"
            ).replace("{0}", selectedOptions.length);
          }

          button.querySelector(".filter-option2").classList.remove("hide");
        }
      }
    }
  }

  static _initGroupSearchAndNavigation(dropdownElement) {
    dropdownElement.querySelector(".search-groups").onchange =
      dropdownElement.querySelector(".search-groups").onkeydown =
      dropdownElement.querySelector(".search-groups").oninput =
        (e) => {
          if (e.type === "keydown") {
            if (
              e.code === "ArrowUp" ||
              e.code === "ArrowDown" ||
              e.key === "Enter"
            ) {
              this._handleNavigation(dropdownElement, e.code);
              e.preventDefault();
              return;
            } else if (e.code === "Tab" && e.shiftKey) {
              e.preventDefault();
              dropdownElement
                .closest(".bootstrap-select")
                .querySelector("button")
                .click();
              dropdownElement
                .closest(".bootstrap-select")
                .querySelector("button")
                .focus();
              return;
            }
          }

          this._refreshGroupSearch(dropdownElement);
        };

    dropdownElement.querySelector(".all-groups-btn").onblur =
      dropdownElement.querySelector(".search-groups").onblur = () => {
        this.allGroupsButtons
          .get(dropdownElement)
          .forEach((el) => el.classList.remove("active"));
      };

    dropdownElement.querySelector(".group-container, .search-groups").onclick =
      (e) => {
        e.stopPropagation();
        e.preventDefault();
      };

    dropdownElement.querySelector(".all-groups-btn").onkeydown = (e) => {
      const hasActiveGroup =
        !!dropdownElement.querySelector(".group-btn.active");

      if (
        e.code === "ArrowUp" ||
        e.code === "ArrowDown" ||
        (e.key === "Enter" && hasActiveGroup)
      ) {
        this._handleNavigation(dropdownElement, e.code);
        e.preventDefault();
      }
    };

    dropdownElement.addEventListener(
      "click",
      (e) => {
        if (e.target.matches(".bs-select-all")) {
          e.target.classList.toggle("selected");
        }
      },
      true
    );

    dropdownElement.addEventListener(
      "keydown",
      (e) => {
        if (e.code === "Tab" && e.shiftKey) {
          if (e.target.matches(".bs-searchbox input")) {
            e.stopPropagation();
            e.preventDefault();
            dropdownElement.querySelector(".all-groups-btn").focus();
          }
        } else if (e.shiftKey) {
          e.stopPropagation();
        } else if (e.code === "Escape") {
          e.preventDefault();
          dropdownElement
            .closest(".bootstrap-select")
            .querySelector("button")
            .click();
          dropdownElement
            .closest(".bootstrap-select")
            .querySelector("button")
            .focus();
        }
      },
      true
    );
  }

  static _handleNavigation(dropdownElement, arrowKey) {
    const forward = arrowKey === "ArrowDown";
    let activeOption = dropdownElement.querySelector(".group-btn.active");
    activeOption = activeOption
      ? activeOption
      : dropdownElement.querySelector(
          '.group-btn:not([style*="display: none"])' +
            (!forward ? ":last-of-type" : "")
        );

    if (!activeOption) {
      return;
    }

    const firstlySelected = !activeOption.classList.contains("active");
    activeOption.classList.add("active");

    if (firstlySelected) {
      this._scrollActiveGroupButtonIntoView(activeOption);
      return;
    }

    if (arrowKey.includes("Enter")) {
      activeOption.click();
    } else {
      let currentOption = activeOption;

      do {
        currentOption = forward
          ? currentOption.nextElementSibling
          : currentOption.previousElementSibling;

        if (!currentOption) {
          currentOption = forward
            ? activeOption.parentElement.firstElementChild
            : activeOption.parentElement.lastElementChild;
        }
      } while (
        currentOption !== activeOption &&
        currentOption.style.display === "none"
      );

      activeOption.classList.remove("active");
      currentOption.classList.add("active");
      this._scrollActiveGroupButtonIntoView(currentOption);
    }
  }

  static _scrollActiveGroupButtonIntoView(currentOption) {
    const offsetTop =
      currentOption.getBoundingClientRect().y -
      currentOption.closest(".groups-container").getBoundingClientRect().y;

    if (offsetTop <= 0 || offsetTop > 135) {
      currentOption.closest(".groups-container").scrollTop += offsetTop - 50;
      this._refreshGroupView(currentOption.closest(".groups-container"));
    }
  }

  static _refreshGroupSearch(dropdownElement) {
    const input = dropdownElement.querySelector(".search-groups");

    if (
      !dropdownElement.dataset.searchingGroups &&
      input.style.display !== "none"
    ) {
      dropdownElement.dataset.searchingGroups = "true";

      setTimeout(() => {
        const value = input.value.toLowerCase().trim();
        const groupContainer =
          dropdownElement.querySelector(".groups-container");
        groupContainer.innerHTML = "";

        this.filteredGroupButtons.set(
          dropdownElement,
          this.allGroupsButtons
            .get(dropdownElement)
            .filter((button) => button.innerHTML.toLowerCase().includes(value))
        );
        this._refreshGroupView(groupContainer);
        this._updateSelectAllGroupsButton(dropdownElement);
        dropdownElement.dataset.searchingGroups = "";
      }, 50);
    }
  }

  static _initGroupButtonClick(selectElement, dropdownElement, groups) {
    const childrenOptionsMap = new Map(
      Object.values(this._getRealInputOrSelect(selectElement).options).map(
        (option) => [+option.value, option]
      )
    );
    const groupMap = new Map(groups.map((group) => [group.id, group]));
    this._updateGroupChildren(
      selectElement,
      dropdownElement,
      childrenOptionsMap,
      groupMap
    );

    dropdownElement.querySelector(".all-groups-btn").onclick = (e) => {
      const selected = e.currentTarget.classList.contains("selected");

      this.filteredGroupButtons.get(dropdownElement).forEach((button) => {
        if (selected) {
          button.classList.remove("selected");
        } else {
          button.classList.add("selected");
        }
      });

      this._updateGroupChildren(
        selectElement,
        dropdownElement,
        childrenOptionsMap,
        groupMap
      );
      e.preventDefault();
      e.stopPropagation();
    };

    dropdownElement.querySelector(".groups-container").onclick = (e) => {
      if (e.target instanceof HTMLButtonElement) {
        e.target.classList.toggle("selected");
        this._updateGroupChildren(
          selectElement,
          dropdownElement,
          childrenOptionsMap,
          groupMap
        );
      }

      e.preventDefault();
      e.stopPropagation();
    };
  }

  static _updateGroupChildren(
    selectElement,
    dropdownElement,
    childrenOptionsMap,
    groupMap
  ) {
    const realSelect = this._getRealInputOrSelect(selectElement);

    const selectedChildrenIds = new Set(
      $(realSelect)
        .val()
        .map((id) => +id)
    );
    const oldChildrenIds = new Set(
      Object.values(realSelect.querySelectorAll("option")).map(
        (option) => +option.value
      )
    );
    const selectedGroupIds = this.allGroupsButtons
      .get(dropdownElement)
      .filter((button) => button.classList.contains("selected"))
      .map((btn) => +btn.dataset.id);
    const addedChildrenIds = new Set();
    const insertedChildren = new Set();

    for (const [id, option] of childrenOptionsMap) {
      if (
        !addedChildrenIds.has(id) &&
        (option.dataset.alwaysShow || groupMap.size === selectedGroupIds.length)
      ) {
        addedChildrenIds.add(id);
        insertedChildren.add(option);
      }
    }

    for (const id of selectedGroupIds) {
      let childIds = groupMap.get(id).childIds;

      for (let childId of childIds) {
        if (!addedChildrenIds.has(childId)) {
          addedChildrenIds.add(childId);
          insertedChildren.add(childrenOptionsMap.get(childId));
        }
      }
    }

    realSelect.replaceChildren(...insertedChildren);

    if (selectElement.dataset.liveSearch) {
      const container = dropdownElement.querySelector(".bs-searchbox");

      if (addedChildrenIds.size < 6) {
        container.classList.add("not-visible");
      } else {
        container.classList.remove("not-visible");
      }
    }

    [...childrenOptionsMap].forEach(
      ([id, option]) =>
        (option.selected =
          selectedChildrenIds.has(id) || !oldChildrenIds.has(id))
    );

    if (this.groupChildrenUpdateTimeout !== null) {
      clearTimeout(this.groupChildrenUpdateTimeout);
    }

    if (dropdownElement.closest(".bootstrap-select.open")) {
      this.groupChildrenUpdateTimeout = setTimeout(() => {
        //this._hideChildrenMenu(dropdownElement);
        this._reloadGroupChildren(selectElement, dropdownElement);
        $(selectElement).trigger("change");
        $(realSelect).trigger("change");
        this.groupChildrenUpdateTimeout = null;
        //this.groupChildrenToInsert.set(dropdownElement, [...this.groupChildren.get(dropdownElement)]);
        this._showChildrenMenuGradually(dropdownElement);
      }, 350);
    } else {
      this._reloadGroupChildren(selectElement, dropdownElement);
      $(selectElement).trigger("change");
      $(realSelect).trigger("change");
      //this.groupChildrenToInsert.set(dropdownElement, [...this.groupChildren.get(dropdownElement)]);
      //this._hideChildrenMenu(dropdownElement);
    }

    // Filtering secondary options
    let buttonsToInsert = [];

    if (selectElement.classList.contains("option2enabled")) {
      let groups2 = JSON.parse(selectElement.dataset.groups2);
      const groupMap2 = new Map(groups2.map((group) => [group.id, group]));
      for (const id of selectedGroupIds) {
        let childIds = groupMap2.get(id).childIds;
        for (let childId of childIds) {
          // groupIds
          for (let button of this.allOption2Buttons.get(selectElement)) {
            if (button.dataset.id == childId) {
              buttonsToInsert.push(button);
            }
            if (button.parentElement == null) {
              button.classList.add("selected");
            }
          }
        }
      }
      this.activeOptions2Buttons.set(selectElement, buttonsToInsert);
      const optionsContainer =
        dropdownElement.querySelector(".options-container");
      optionsContainer.innerHTML = "";
      optionsContainer.append(...buttonsToInsert);

      const input = dropdownElement.querySelector(".search-option2");
      const container = dropdownElement.querySelector(
        ".options2-input-container"
      );

      const value = input.value.toLowerCase().trim();
      optionsContainer.innerHTML = "";
      this.filteredOptions2Buttons.set(
        selectElement,
        this.activeOptions2Buttons.get(selectElement).filter(function (button) {
          return (
            button.innerHTML.toLowerCase().includes(value) &&
            button.classList.contains("active")
          );
        })
      );

      if (this.activeOptions2Buttons.get(selectElement).length < 6) {
        container.classList.add("hide");
      } else {
        container.classList.remove("hide");
      }

      this._refreshOption2Container(selectElement, optionsContainer);
      this._updateSelectAllOptions2Button(selectElement, dropdownElement);
    }
    this._updateSelectAllGroupsButton(dropdownElement);
    this._updateModifiedStatus(selectElement);

    $(selectElement).trigger("change");
    setTimeout(() => {
      dropdownElement.closest(".bootstrap-select").style.pointerEvents = "";
    });
  }

  static _showChildrenMenuGradually(dropdownElement) {
    const innerMenu = dropdownElement.querySelector(".inner");

    if (innerMenu.scrollTop >= innerMenu.scrollHeight - 400) {
      const childrenTodd = this.groupChildrenToInsert
        .get(dropdownElement)
        .filter((child) => !child.parentElement)
        .slice(0, 20);
      innerMenu.append(...childrenTodd);
    }
  }

  static _updateSelectAllGroupsButton(dropdownElement) {
    const filteredGroupButtons = this.filteredGroupButtons.get(dropdownElement);
    const visibleSelectedGroupsCount = filteredGroupButtons.filter((button) =>
      button.classList.contains("selected")
    ).length;
    const visibleGroupsCount = filteredGroupButtons.length;

    if (visibleSelectedGroupsCount === visibleGroupsCount) {
      dropdownElement
        .querySelector(".all-groups-btn")
        .classList.add("selected");
    } else {
      dropdownElement
        .querySelector(".all-groups-btn")
        .classList.remove("selected");
    }
  }

  static getAllGroups(selectElement) {
    if (!(selectElement instanceof HTMLElement)) {
      selectElement = $(selectElement)[0];
    }

    if (!selectElement) {
      return [];
    }

    if (selectElement.dataset.groups) {
      return JSON.parse(selectElement.dataset.groups);
    }
  }

  static getSelectedGroupIds(selectElement) {
    if (!(selectElement instanceof HTMLElement)) {
      selectElement = $(selectElement)[0];
    }

    if (!selectElement) {
      return [];
    }

    const dropdownElement = selectElement.previousElementSibling;
    console.log(dropdownElement);
    if (dropdownElement.querySelector(".groups-container")) {
      return this.allGroupsButtons
        .get(dropdownElement)
        .filter((button) => button.classList.contains("selected"))
        .map((btn) => +btn.dataset.id);
    } else if (selectElement.dataset.groups) {
      const groups = JSON.parse(selectElement.dataset.groups);
      return groups.filter((group) => group.selected).map((group) => group.id);
    }

    return [];
  }

  static getSelectedGroup2Ids(selectElement) {
    if (!(selectElement instanceof HTMLElement)) {
      selectElement = $(selectElement)[0];
    }

    if (!selectElement) {
      return [];
    }

    const dropdownElement = selectElement.previousElementSibling;

    if (dropdownElement.querySelector(".options-container")) {
      return this.activeOptions2Buttons
        .get(selectElement)
        .filter((button) => button.classList.contains("selected"))
        .map((btn) => +btn.dataset.id);
    } else if (selectElement.dataset.groups2) {
      const groups = JSON.parse(selectElement.dataset.groups2);
      const children = groups
        .filter((group) => group.selected)
        .map((group) => group.childIds);
      let ret = [];
      for (let child of children) {
        for (let id of child) {
          ret.push(id);
        }
      }
      return ret;
    }

    return [];
  }

  static addValuesToData(values, data, prefix) {
    if (values instanceof Array && values.length) {
      data[prefix + "_values"] = values.join(",");
    }
  }

  static initSimpleSelects() {
    document
      .querySelectorAll("select.selectpicker2")
      .forEach((selectElement) => {
        this.initSimpleSelect(selectElement);
      });
  }

  static initSimpleSelect(selectElement) {
    if (!(selectElement instanceof HTMLElement)) {
      selectElement = $(selectElement)[0];
    }

    if (!selectElement || this.simpleSelectAllChildren.has(selectElement)) {
      return;
    }

    const selectShadowCopy = selectElement.cloneNode(true);
    selectElement.innerHTML = "";
    selectElement.dataset.options = "[]";
    this.shadowSelects.set(selectElement, selectShadowCopy);

    const dropdownContainer = document.createElement("div");
    dropdownContainer.className = `custom-select btn-group bootstrap-select show-tick form-control ${selectElement.id}`;
    dropdownContainer.innerHTML =
      `<button type="button" class="btn dropdown-toggle btn-default" role="button" data-id="${selectElement.id}" title="" data-group-status-text="">
          <span class="filter-option pull-left"></span>&nbsp;<span class="bs-caret"><span class="caret"></span></span></button>` +
      `<div class="dropdown-menu open" role="combobox" style="max-height: 500px; overflow: hidden; min-height: 20px;">
               <div class="bs-searchbox"><input type="text" class="form-control" autocomplete="off" role="textbox"></div>
               <ul class="dropdown-menu inner" role="listbox" style="max-height: 480px; overflow-y: auto; min-height: 80px;"></ul>
           </div>`;

    selectElement.after(dropdownContainer);
    dropdownContainer.appendChild(selectElement);

    const toggle = dropdownContainer.querySelector(".dropdown-toggle");
    const innerMenu = dropdownContainer.querySelector(".inner");
    const searchInput = dropdownContainer.querySelector(".bs-searchbox input");

    const allChildren = [];
    for (const option of selectShadowCopy.children) {
      const element = document.createElement("li");
      element.dataset.value = option.value;

      if (option.selected) {
        element.classList.add("selected", "active");
        toggle.firstElementChild.innerText = option.innerText;
      }

      element.innerHTML = `<a><span class="text">${option.innerHTML} <span class="text-muted"></span></span></a>`;

      if (option.dataset.subtext) {
        element.firstElementChild.firstElementChild.firstElementChild.innerText =
          option.dataset.subtext;
      }

      element.onclick = (e) => {
        const listItem = e.target.closest("li");
        const oldListItem = allChildren[selectShadowCopy.selectedIndex];
        oldListItem.classList.remove("selected", "active");
        listItem.classList.add("selected", "active");
        selectShadowCopy.value = listItem.dataset.value;
        toggle.firstElementChild.innerText =
          selectShadowCopy.options[selectShadowCopy.selectedIndex].text;

        $(selectShadowCopy).trigger("change");
      };

      allChildren.push(element);
    }

    this.simpleSelectAllChildren.set(selectElement, allChildren);

    toggle.onclick = toggle.onkeydown = (e) => {
      if (
        e.type === "keydown" &&
        !(e.code === "ArrowUp" || e.code === "ArrowDown")
      ) {
        return;
      }

      document
        .querySelectorAll('.dropdown-toggle[aria-expanded="true"]')
        .forEach((t) => {
          t.click();
        });

      setTimeout(() => {
        dropdownContainer.classList.toggle("open");
        toggle.classList.toggle("active");

        if (toggle.classList.contains("active")) {
          this._search(selectElement, innerMenu, searchInput.value);
        }

        setTimeout(() => {
          searchInput.focus();
        }, 10);
      }, 10);

      e.stopPropagation();
    };

    searchInput.onblur = (e) => {
      if (
        document.activeElement &&
        dropdownContainer.contains(document.activeElement)
      ) {
        return;
      }

      setTimeout(() => {
        innerMenu.innerHTML = "";
        dropdownContainer.classList.remove("open");
        toggle.classList.remove("active");
        toggle.focus();
      }, 200);
    };

    function onBlur(e) {
      if (
        !(e.target instanceof HTMLElement) ||
        !dropdownContainer.contains(e.target)
      ) {
        setTimeout(() => {
          innerMenu.innerHTML = "";
          dropdownContainer.classList.remove("open");
          toggle.classList.remove("active");
        }, 100);
      }
    }

    window.addEventListener("click", onBlur, true);
    window.addEventListener("focus", onBlur, true);
    window.addEventListener("mouseup", onBlur, true);
    window.addEventListener("mousedown", onBlur, true);

    innerMenu.onscroll = (e) => {
      this._updateInnerMenuView(selectElement, innerMenu, searchInput.value);
    };

    searchInput.oninput = (e) => {
      this._search(selectElement, innerMenu, searchInput.value);
    };

    searchInput.onkeydown = (e) => {
      const children = this.simpleSelectFilteredChildren
        .get(selectElement)
        .filter((item) => !!item.parentElement);
      let selectedIndex = children.findIndex((item) =>
        item.classList.contains("active")
      );
      selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;

      if (selectedIndex < children.length) {
        const offsetTop =
          children[selectedIndex].getBoundingClientRect().y -
          innerMenu.parentElement.getBoundingClientRect().y;

        if (offsetTop <= 50 || offsetTop > 335) {
          innerMenu.scrollTop += offsetTop - 100;
          this._updateInnerMenuView(
            selectElement,
            innerMenu,
            searchInput.value
          );
        }
      }

      if (e.code === "ArrowUp" && selectedIndex > 0) {
        children[selectedIndex].classList.remove("active");
        children[selectedIndex - 1].classList.add("active");
      }

      if (e.code === "ArrowDown" && selectedIndex < children.length - 1) {
        children[selectedIndex].classList.remove("active");
        children[selectedIndex + 1].classList.add("active");
      }

      if (e.key === "Enter" && selectedIndex < children.length) {
        children[selectedIndex].click();
        searchInput.blur();
      }
    };
  }

  static _search(selectElement, innerMenu, text) {
    const lowercaseText = text.toLowerCase();
    innerMenu.innerHTML = "";
    const filteredChildren = this.simpleSelectAllChildren
      .get(selectElement)
      .filter((child) => child.innerHTML.toLowerCase().includes(lowercaseText));
    this.simpleSelectFilteredChildren.set(selectElement, filteredChildren);
    this._updateInnerMenuView(selectElement, innerMenu, text);
  }

  static _updateInnerMenuView(selectElement, innerMenu, text) {
    const lastChild = innerMenu.lastElementChild;
    const offsetTop = lastChild
      ? lastChild.getBoundingClientRect().y -
        innerMenu.getBoundingClientRect().y
      : 1000;

    if (offsetTop <= 1000) {
      const allChildren = this.simpleSelectFilteredChildren.get(selectElement);

      if (!innerMenu.firstElementChild) {
        allChildren.forEach((child) => {
          if (
            child.classList.contains("active") &&
            !child.classList.contains("selected")
          ) {
            child.classList.remove("active");
          } else if (child.classList.contains("selected")) {
            child.classList.add("active");
          }
        });
      }

      const childrenTodd = allChildren
        .filter((child) => !child.parentElement)
        .slice(0, 100);
      innerMenu.append(...childrenTodd);

      if (!innerMenu.firstElementChild) {
        innerMenu.innerHTML = `<li class="no-results">${translate(
          "no-results"
        ).replace("{0}", `"${text}"`)}</li>`;
      }
    }
  }
}

MultiSelectHelper.dropdownQueue = new WeakSet();
MultiSelectHelper.groupChildrenUpdateTimeout = null;
MultiSelectHelper.groupChildrenToInsert = new WeakMap();
MultiSelectHelper.groupChildren = new WeakMap();

MultiSelectHelper.simpleSelectAllChildren = new WeakMap();
MultiSelectHelper.simpleSelectFilteredChildren = new WeakMap();

MultiSelectHelper.filteredGroupButtons = new WeakMap();
MultiSelectHelper.allGroupsButtons = new WeakMap();
MultiSelectHelper.shadowSelects = new WeakMap();
MultiSelectHelper.allOption2Buttons = new WeakMap();
MultiSelectHelper.filteredOptions2Buttons = new WeakMap();
MultiSelectHelper.activeOptions2Buttons = new WeakMap();
