window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
$ = window.$ = window.jQuery = window.JQuery = require("jquery");
window.translate = function (key) {
  let translations = {
    "all": {
      de: "Alle",
      en: "All",
      it: "Tutti",
    },
    "selected": {
      de: "{0} ausgewählt",
      en: "{0} selected",
      it: "{0} selezionata",
    },
    "please-choose": {
      de: "Bitte wählen...",
      en: "Please choose...",
      it: "Si prega di scegliere...",
    },
    "none": {
      de: "Keine",
      en: "None",
      it: "Nessuna",
    },
    "limit-reached": {
      de: "Limit erreicht ({n} Element max.)",
      en: "Limit reached ({n} element max.)",
      it: "Limite raggiunto ({n} elemento max.)",
    },
    "limit-reached-plural": {
      de: "Limit erreicht ({n} Elemente max.)",
      en: "Limit reached ({n} elements max.)",
      it: "Limite raggiunto ({n} elementi max.)",
    },
    "group-limit-reached": {
      de: "Gruppen-Limit erreicht ({n} Element max.)",
      en: "Group limit reached ({n} item max.)",
      it: "Limite gruppo raggiunto ({n} elemento max.)",
    },
    "group-limit-reached-plural": {
      de: "Gruppen-Limit erreicht ({n} Elemente max.)",
      en: "Group limit reached ({n} items max.)",
      it: "Limite di gruppo raggiunto ({n} elementi max.)",
    },
    "no-results": {
      de: "Keine Ergebnisse für {0}",
      en: "No results for {0}",
      it: "Nessun risultato per {0}",
    },
    "count-selected": {
      de: "{0} Element ausgewählt",
      en: "{0} item selected",
      it: "{0} elemento selezionato"
    },
    "count-selected-plural": {
      de: "{0} Elemente Ausgewählt",
      en: "{0} items selected",
      it: "{0} elementi selezionati"
    },
    "select-all": {
      de: "Wählen Sie Alle",
      en: "Select All",
      it: "Seleziona tutto"
    },
  };

  if (translations[key]) {
    if (translations[key][document.documentElement.lang]) {
      return translations[key][document.documentElement.lang];
    }

    return translations[key]["de"];
  }

  return key + " (not found)";
};

require("sticky-table-headers");

/**
 * load toastr
 * @link https://github.com/CodeSeven/toastr
 */
window.toastr = require("toastr");

/**
 * load the Pusher library, more configuration is done in PusherHelper.js class
 */
window.Pusher = require("pusher-js");

require("bootstrap");
/**
 * load bootstrap-datepicker
 */
window.datepicker = require("bootstrap-datepicker");

/**
 * load bootstrap-switch
 * @link https://github.com/Bttstrp/bootstrap-switch/
 */
window.bootstrapSwitch = require("bootstrap-switch");

/**
 * load  bootstrap-daterangepicker
 * @link https://github.com/dangrossman/daterangepicker
 */
window.daterangepicker = require("bootstrap-daterangepicker");

/**
 * load bootstrap-multiselectit
 * @link https://github.com/davidstutz/bootstrap-multiselect
 * @link https://davidstutz.github.io/bootstrap-multiselect
 */
window.multiselect = require("bootstrap-multiselect");
// require("bootstrap-multiselect");

require("bootstrap-select");

/**
 * load typeahead
 */
window.typeahead = require("bootstrap-3-typeahead");

/**
 * load typeahead
 */
window.bootstrapSwitch = require("bootstrap-switch");

/**
 * load datatables
 * @link https://datatables.net/
 */
window.dataTables = require("datatables.net");
require("../../public/js/libs/datatables.min"); // bootstrap plugin

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
