export default class AjaxHandler {
  static handleError(error) {
    if (error.status === 0) {
      // request aborted
    } else if (error.status === 400) {
      return location.reload();
    } else {
      return alert(error.status + ": " + error.statusText);
    }
  }
}
