export default class ToastManager {
  static instance;

  static initClass() {
    ToastManager.instance = null;
  }

  static getInstance() {
    return ToastManager.instance != null
      ? ToastManager.instance
      : (ToastManager.instance = new ToastManager());
  }

  constructor() {
    this.toasts = [];
    this.timeoutId = -1;
  }

  addToast(options) {
    return this.toasts.push(options);
  }

  run() {
    if (this.toasts.length > 0) {
      toastr.options.showDuration = 1000;
      toastr.remove();
      const options = this.toasts.pop();
      toastr.success(options[0], options[1], options[2]);
      this.toasts = [];

      return (this.timeoutId = setTimeout(() => {
        return this.run();
      }, 250));
    } else {
      return (this.timeoutId = setTimeout(() => {
        return this.run();
      }, 250));
    }
  }

  stop() {
    this.toasts = [];
    return clearTimeout(this.timeoutId);
  }
}
