export default class TimeoutRefresher {
  static initClass() {
    this.time = null;
  }

  constructor() {
    this.time = new Date().getTime();

    $(document).bind("mousemove keyup", (e) => {
      return (this.time = new Date().getTime());
    });

    setTimeout(() => {
      return this.refresh();
    }, 1000);
  }

  refresh() {
    if (new Date().getTime() - this.time >= 600000) {
      return (window.location = "/auth/barcode-logout");
    }

    return setTimeout(() => {
      return this.refresh();
    }, 1000);
  }
}
